function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
var Primary;
(function(Primary) {
    Primary["Primary100"] = "#FFFFFF";
    Primary["Primary200"] = "#BABBBF";
    Primary["Primary300"] = "#727272";
    Primary["Primary400"] = "#646464";
})(Primary || (Primary = {}));
var Secondary;
(function(Secondary) {
    Secondary["Secondary100"] = "#565656";
    Secondary["Secondary200"] = "#484848";
    Secondary["Secondary300"] = "#404040";
    Secondary["Secondary400"] = "#323232";
})(Secondary || (Secondary = {}));
var Tertiary;
(function(Tertiary) {
    Tertiary["Tertiary100"] = "#242424";
    Tertiary["Tertiary200"] = "#161616";
    Tertiary["Tertiary300"] = "#080808";
    Tertiary["Tertiary400"] = "#000000";
})(Tertiary || (Tertiary = {}));
var Positive;
(function(Positive) {
    Positive["Positive100"] = "#5FBF79";
    Positive["Positive200"] = "#284230";
    Positive["Positive300"] = "#122016";
})(Positive || (Positive = {}));
var Negative;
(function(Negative) {
    Negative["Negative100"] = "#DB3236";
    Negative["Negative200"] = "#491816";
    Negative["Negative300"] = "#280E0F";
})(Negative || (Negative = {}));
var Neutral;
(function(Neutral) {
    Neutral["Neutral100"] = "#F6BF4F";
    Neutral["Neutral200"] = "#4E432D";
    Neutral["Neutral300"] = "#3A2F1B";
})(Neutral || (Neutral = {}));
var Accent;
(function(Accent) {
    Accent["Accent100"] = "#FED4D4";
})(Accent || (Accent = {}));
export var Base = _objectSpread({}, Primary, Secondary, Tertiary, Positive, Negative, Neutral, Accent);
