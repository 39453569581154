function _taggedTemplateLiteral(strings, raw) {
    if (!raw) {
        raw = strings.slice(0);
    }
    return Object.freeze(Object.defineProperties(strings, {
        raw: {
            value: Object.freeze(raw)
        }
    }));
}
function _templateObject() {
    var data = _taggedTemplateLiteral([
        "\n    ",
        "\n    font-size: ",
        ";\n    line-height: ",
        ";\n    letter-spacing: ",
        ";\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _taggedTemplateLiteral([
        "\n    ",
        "\n    font-size: ",
        ";\n    line-height: ",
        ";\n    letter-spacing: ",
        ";\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _taggedTemplateLiteral([
        "\n    ",
        "\n    font-size: ",
        ";\n    line-height: ",
        ";\n    letter-spacing: ",
        ";\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _taggedTemplateLiteral([
        "\n    ",
        ";\n    font-size: ",
        ";\n    line-height: ",
        ";\n    letter-spacing: ",
        ";\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _taggedTemplateLiteral([
        "\n    ",
        ";\n    font-size: ",
        ";\n    line-height: ",
        ";\n    letter-spacing: ",
        ";\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _taggedTemplateLiteral([
        "\n    ",
        "\n    font-size: ",
        ";\n    line-height: ",
        ";\n    text-transform: uppercase;\n    letter-spacing: ",
        ";\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _taggedTemplateLiteral([
        "\n    ",
        "\n    font-size: ",
        ";\n    line-height: ",
        ";\n    letter-spacing: ",
        ";\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _taggedTemplateLiteral([
        "\n    ",
        "\n    font-size: ",
        ";\n    line-height: ",
        ";\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _taggedTemplateLiteral([
        "\n    ",
        "\n    font-size: ",
        ";\n    line-height: ",
        ";\n\n    @media screen and (min-width: ",
        ") {\n        font-size: ",
        ";\n        line-height: ",
        ";\n    }\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
import { rem } from "polished";
import { css } from "styled-components";
import { Breakpoint } from "../css/breakpoints";
import { Typography } from "../legacy/styles";
export var globalTitle = css(_templateObject(), Typography.FontMedium, rem(20), rem(26), rem(0.2));
export var globalBodyPrimary = css(_templateObject1(), Typography.FontNormal, rem(16), rem(22), rem(0.1));
export var globalBodySecondary = css(_templateObject2(), Typography.FontNormal, rem(13), rem(17), rem(0.1));
export var globalCtaSmall = css(_templateObject3(), Typography.FontMedium, rem(13), rem(17), rem(0.2));
export var globalCtaLarge = css(_templateObject4(), Typography.FontMedium, rem(16), rem(24), rem(0.2));
export var globalLabel = css(_templateObject5(), Typography.FontBold, rem(11), rem(16), rem(1.4));
export var heading1 = css(_templateObject6(), Typography.FontMedium, rem(72), rem(79), rem(-1));
export var heading2 = css(_templateObject7(), Typography.FontMedium, rem(46), rem(53));
export var heading3 = css(_templateObject8(), Typography.FontMedium, rem(24), rem(28), rem(Breakpoint.Small), rem(30), rem(34));
